import ToolTipComponent from "@CubeComponents/html/tooltip";
import IconComponent from "@CubeComponents/icons";
import {ButtonComponentProps, StylePropertyListType} from "../interface";
import {BUTTON_TYPES, COLORS, CONSTANTS} from "./globalVariable";
import { SignTypeValues } from ".";

export const getStylePropertyList=({
  first,
  last,
  incBy,
  prefix="",
  postfix="",
}: StylePropertyListType): string[] => {
  const returnArray=[];
  for(var i=first;i<=last;i=i+incBy) {
    returnArray.push(`${prefix}${i}${postfix}`);
  }
  return returnArray;
};

export const get_button_component_custom_style=({
  gradientColor1,
  gradientColor2,
  fontWeight,
  borderRadius,
  gradientTextColor2,
  gradientTextColor1,
  borderColor,
  borderWidth,
  borderType,
  padding,
  bgColor,
  btnColor,
  gradientBorderColor1,
  gradientBorderColor2,
  disabled,
  buttonType,
  height,
  width,
  textAlign
}: ButtonComponentProps) => {
  return {
    ...(!disabled&&
      !buttonType&&{
      fontWeight: fontWeight,
      textAlign: textAlign,
      height: height,
      width: width,
      borderRadius: `${borderRadius? borderRadius:0}`,
      background: `${gradientColor1&&gradientColor2
        ? `linear-gradient(100.44deg,  ${gradientColor1} 0%, ${gradientColor2} 110.4%)`
        :bgColor
          ? bgColor
          :gradientTextColor1&&gradientTextColor2
            ? `-webkit-linear-gradient(${gradientTextColor1}, ${gradientTextColor2})`
            :CONSTANTS.BLANK
        }`,
      ...(gradientTextColor1&&
        gradientTextColor2&&{
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }),
      color: `${btnColor? btnColor:CONSTANTS.BLANK}`,
      borderColor: `${borderColor? borderColor:CONSTANTS.BLANK}`,
      borderWidth: `${borderWidth? borderWidth:"0px"}`,
      borderStyle: `${borderType? borderType:CONSTANTS.NONE}`,
      padding: `${padding? padding:CONSTANTS.NONE}`,
      ...(gradientBorderColor1&&
        gradientBorderColor2&&{
        borderStyle: borderType,
        borderWidth: borderWidth,
        borderImage: `linear-gradient(45deg, ${gradientBorderColor1}, ${gradientBorderColor2}) 1`,
      }),
    }),
    ...(disabled&&
      !buttonType&&{
      background: "#ECF3FE",
      color: "rgba(25, 32, 72, 0.5)",
      cursor: "not-allowed",
    }),
  };
};

export const btn_Focus_common=() => {
  return {outline: "none"}
}

export const getButtonCommonCss=(isInfo: boolean) => {
  return {
    borderRadius: isInfo? "var(--size-25)":"var(--size-5) !important",
    border: `${CONSTANTS.NONE} !important`,
  }
}

export const get_normal_gradient_btn=(isInfo: boolean=false) => {
  return {
    background: `linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
    ...getButtonCommonCss(isInfo),
    color: `var(--surface-white) !important`,
    marginLeft: "0 !important",
    "&:hover": {
      background: `linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
      border: CONSTANTS.NONE,
      color: `var(--surface-white)`,
      boxShadow: 'var(--size-2) var(--size-2) var(--size-4) var(--size-0) var(--button-hover-shadow)',
    },
    "&:focus": {
      color: `var(--surface-white)`,
      ...btn_Focus_common()
    },
    "&:active": {
      background: `linear-gradient(100.44deg, var(--primary-gradient-color2) 0%, var(--primary-gradient-color2) 110.4%)`,
    },
    "&:disabled": {
      background: `var(--surface-neutral) !important`,
      color: `var(--disabled-text-gray) !important`,
      cursor: "not-allowed",
    },
  };
};

export const get_normal_background_btn=(isInfo=false) => {
  return {
    background: `var(--button-light)`,
    borderRadius: isInfo? "var(--size-20)":"var(--size-5)",
    border: CONSTANTS.NONE,
    color: `var(--primary-text)`,
    "&:focus": {
      ...btn_Focus_common(),
      background: `var(--button-light)`,
    },
    "&:hover": {
      background: `var(--button-light)`,
      border: CONSTANTS.NONE,
      color: `var(--primary-text)`,
      boxShadow: 'var(--size-2) var(--size-2) var(--size-4) var(--size-0) var(--button-hover-shadow)',
    },
    "&:active": {
      background: `var(--light-blue)`,
    },
    "&:disabled": {
      background: `var(--button-light)`,
      color: `var(--disabled-text-gray)`,
      cursor: "not-allowed",
    },
  };
};

export const get_info_background_btn=() => {
  return {
    background: `var(--button-light)`,
    borderRadius: "var(--size-20)",
    border: CONSTANTS.NONE,
    color: `var(--primary-text)`,
    "&:focus": {
      ...btn_Focus_common(),
      background: `var(--primary-blue)`,
      color: `var(--primary-text)`,
    },
    "&:hover": {
      background: `var(--button-light)`,
      color: `var(--primary-text)`,
      boxShadow: 'var(--size-2) var(--size-2) var(--size-4) var(--size-0) var(--button-hover-shadow)',
    },
    "&:active": {
      background: `var(--primary-blue)`,
      color: `var(--primary-text)`,

    },
    "&:disabled": {
      background: `var(--button-light)`,
      color: `var(--disabled-text-gray)`,
      cursor: "not-allowed",
    },
  };
};

export const get_normal_border_btn=() => {
  return {
    overflow: "unset",
    position: "relative",
    background: "transparent !important",
    zIndex: "1",
    color: "var(--primary-text)",
    border: 'none',
    "&:before": {
      position: 'absolute',
      top: 'var(--size-0)',
      bottom: 'var(--size-0)',
      left: 'var(--size-0)',
      right: 'var(--size-0)',
      background: 'var(--surface-white)',
      content: '""',
      zIndex: '-1',
      borderRadius: 'var(--size-4)',
    },
    "&:hover:before, &:focus:before": {
      background: 'linear-gradient(100.44deg, var(--primary-gradient-color1) 0%,  var(--primary-gradient-color2) 110.4%)',
    },
    "&:after": {
      position: 'absolute',
      top: 'var(--size-neg-1)',
      bottom: 'var(--size-neg-1)',
      left: 'var(--size-neg-1)',
      right: 'var(--size-neg-1)',
      background: 'linear-gradient(100.44deg, var(--primary-gradient-color1) 0%,  var(--primary-gradient-color2) 110.4%)',
      content: '""',
      zIndex: '-2',
      borderRadius: 'var(--size-5)',
    },
    marginLeft: "0 !important",
    "&:hover, &:focus": {
      color: `var(--surface-white)`,
    },
    "&:hover": {
      boxShadow: 'var(--size-2) var(--size-2) var(--size-4) var(--size-0) var(--button-hover-shadow)',
    },
    "&:focus": {
      ...btn_Focus_common(),
      color: `var(--surface-white)`,
    },
    "&:disabled": {
      border: `var(--size-2) var(--border-solid) var(--disabled-border) !important`,
      background: 'var(--surface-white)',
      WebkitBackgroundClip: "var(--padding-box) !important",
      WebkitTextFillColor: `var(--disabled-text-gray) !important`,
      borderRadius: "var(--size-5) !important",
      cursor: "var(--not-allowed) !important",
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      }
    },
  };
};

export const get_normal_text_btn=() => {
  return {
    border: CONSTANTS.NONE,
    background: "var(--surface-white)",
    borderRadius: "var(--size-5)",
    color: "var(--primary-text)",
    "&:hover": {
      background: `var(--info-lighter-blue)`,
      boxShadow: 'var(--size-2) var(--size-2) var(--size-4) var(--size-0) var(--button-hover-shadow)',
    },
    "&:focus": {
      ...btn_Focus_common(),
      background: "var(--surface-white)",
    },
    "&:active": {
      background: `var(--info-light-blue)`,
    },

    "&:disabled": {
      background: `var(--none)`,
      WebkitBackgroundClip: "var(--padding-box)",
      WebkitTextFillColor: `var(--disabled-text-gray)`,
      borderRadius: "var(--size-5)",
      cursor: "var(--not-allowed)",
    },
  };
};

export const get_btn_other=(btnType: string) => {
  const bgColor=getOtherBtnColor(btnType);
  return {
    background: bgColor,
    borderRadius: "var(--size-16)",
    color: `var(--surface-white)`,
    border: CONSTANTS.NONE,
    "&:hover, &:focus": {
      background: bgColor,
      ...btn_Focus_common(),
      color: `var(--surface-white)`,
    },
    '&:hover': {
      boxShadow: 'var(--size-2) var(--size-2) var(--size-4) var(--size-0) var(--button-hover-shadow)',
    },
    "&:disabled": {
      background: `var(--button-light)`,
      color: `var(--disabled-text-gray)`,
      cursor: "var(--not-allowed)",
    },
  };
};

const getOtherBtnColor=(btnType: string) => {
  if(btnType===BUTTON_TYPES.ACTIVE_BUTTON) {
    return "var(--positive-communication)";
  }
  if(btnType===BUTTON_TYPES.IN_ACTIVE_BUTTON) {
    return "var(--negative-communication)";
  }
  if(btnType===BUTTON_TYPES.IN_PROGRESS_BUTTON) {
    return "var(--warning)";
  }
};

export const getTextboxStyle=(isDisabled: boolean) => {
  return {
    color: "var(--primary-text)",
    height: "var(--size-32)",
    ...(isDisabled&&{
      background: "var(--button-light)",
      border: "var(--none) !important",
    }),
  };
};

export const getTextboxParentStyle=(
  widthInPercentage: string,
  margin?: string
) => {
  return {
    borderRadius: "var(--size-2)",
    width: widthInPercentage,
    border: "var(--none)",
    margin: `${margin} !important`,
  };
};

export const getTextboxClassStyle=() => {
  return {
    outline: CONSTANTS.NONE,
    borderRadius: "var(--size-2) !important",
    borderColor: "var(--text-light) !important",
    "&:not(.e-disabled):not(.e-input-focus):hover": {
      borderColor: "var(--primary-text) !important",
    },
    "&.e-disabled:hover.e-disabled": {
      borderColor: "var(--transparent)",
    },
    "&.e-input-focus": {
      borderColor: "var(--inner-border) !important",
    },
    "&.e-input-group": {
      border: "1px solid var(--primary-blue) !important",
    },
    "& .e-spin-down,& .e-spin-up,& .e-input-group-icon": {
      background: "var(--transparent) !important",
      color: "var(--primary-text) !important",
    },
    "& .e-spin-down:hover,& .e-spin-up:hover, & .e-input-group-icon:hover": {
      background: "var(--button-light) !important",
      color: "var(--center-border) !important",
    },
    "& .e-spin-down:active,& .e-spin-up:active, & .e-input-group-icon:active": {
      background: "var(--lighter-gray) !important",
      color: "var(--center-border) !important",
    },
    '& textarea': {
      resize: 'none !important'
    },
    "&.e-disabled": {
      borderRadius: "var(--size-2) !important",
      '& .e-input': {
        borderRadius: "var(--size-2) !important",
      },
      border: "var(--none) !important",
    },
    '& .e-input[disabled]': {
      WebkitTextFillColor: 'var(--disabled-text-gray) !important'
    }
  };
};

export const get_splitGradientButton=() => {
  return {
    background: `linear-gradient(100.44deg,  var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
    display: "var(--display-inline-block)",
    borderRadius: "var(--size-2)",
    // border:'none !important',
    "&.e-dropdown-popup.e-popup.e-control": getDropdownCommonCss(),
    color: `var(--surface-white) !important`,
    "&:hover, &:focus": {
      color: `var(--surface-white)`,
    },
    "& > :nth-child(2)": {
      height: "var(--size-32)",
      width: "var(--size-32)",
      borderLeft: CONSTANTS.NONE,
      background: "none",
      color: `var(--surface-white)`,
      border: CONSTANTS.NONE,
      '& .e-btn-icon.e-icons.e-caret': {
        paddingRight: "var(--size-6) !important",
        '&:before': {
          content: 'url(/assets/icons/svg/downArrow.svg) !important'
        }
      },
      paddingLeft: '10px !important',
      '&.e-btn .e-btn-icon': {
        width: "auto !important"
      },
    },
    "& > :nth-child(2):hover": {
      background: `var(--split-btn-opacity-hover)`,
      color: `var(--surface-white) !important`,
    },
    "& > :nth-child(2):focus": {
      outline: "none",
      background: `var(--split-btn-opacity-hover)`,
    },
    "& > :nth-child(1)": {
      outline: "none",
      borderRight: CONSTANTS.NONE,
      background: "var(--none)",
      border: CONSTANTS.NONE,
    },
    '&.disabled': {
      background: "var(--button-light) !important",
      cursor: "var(--not-allowed)",
      color: `var(--disabled-text-gray) !important`,
      "& > :nth-child(2)": {
        '& .e-btn-icon.e-icons.e-caret': {
          paddingRight: "var(--size-6) !important",
          '&:before': {
            content: 'url(/assets/icons/svg/downArrowGray.svg) !important'
          }
        },
      }
    }

    // "& > :nth-child(2) > ::after": {
    //   content: '""',
    //   position: "var(--position-absolute)",
    //   display: "var(--display-block)",
    //   height: "60%",
    //   width: "var(--size-1)",
    //   background: `var(--surface-white) !important`,
    //   left: "var(--size-0)",
    //   top: "var(--size-6)",
    // },
  };
};


export const get_splitBackgroundButton=() => {
  return {
    backgroundColor: `var(--button-light)`,
    display: "var(--display-inline-block)",
    borderRadius: "var(--size-2)",
    "&.e-dropdown-popup.e-popup.e-control": getDropdownCommonCss(),
    color: `var(--primary-text)`,
    "&:hover, &:focus": {
      color: `var(--primary-gradient-color2)`,
    },
    "& > :nth-child(2)": {
      borderLeft: "var(--none)",
      position: "var(--position-relative)",
      background: "var(--none)",
      color: `var(--primary-text)`,
      height: "var(--size-32)",
      width: "var(--size-32)",
      padding: "var(--size-2) var(--size-3)",
      border: "var(--none)",
    },
    "& > :nth-child(2):hover": {
      background: "var(--light-gray)",
      color: `var(--primary-text)`,
    },
    "& > :nth-child(2):focus": {
      background: "var(--light-blue)",
      color: `var(--primary-text)`,
    },
    "& > :nth-child(1)": {
      borderRight: "var(--none)",
      background: "var(--none)",
      border: "var(--none)",
    },
    "& > :nth-child(2) > ::after": {
      content: '""',
      position: "var(--position-absolute)",
      display: "var(--display-block)",
      height: "60%",
      width: "var(--none)",
      background: `var(--light-blue) !important`,
      left: "var(--size-0)",
      top: "var(--size-6)",
    },
  };
};

export const getSplitButtonPositionClassStyle=(
  gradientColor1: string,
  gradientColor2: string,
  bgColor: string
) => {
  return {
    background: `${gradientColor1&&gradientColor2
      ? `linear-gradient(100.44deg,  ${gradientColor1} 0%, ${gradientColor2} 110.4%)`
      :bgColor
        ? bgColor
        :"var(--none)"
      }`,
    display: "var(--display-inline-block)",
  };
};

export const getSplitButtonCustomClassStyle=(
  color: string,
  dropdownArrowBgColor: string,
  barColor: string
) => {
  return {
    "& > :nth-child(2)": {
      color: `${color? color:COLORS.BLACK}`,
      paddingLeft: 10,
      paddingRight: 10,
      borderLeft: CONSTANTS.NONE,
      position: "var(--position-relative)",
      background: dropdownArrowBgColor? dropdownArrowBgColor:"transparent",
    },

    "& > :nth-child(2) > ::after": {
      content: '""',
      position: "var(--position-absolute)",
      display: "var(--display-block)",
      height: "70%",
      width: "var(--size-1-dot-5)",
      background: barColor,
      left: "var(--size-0)",
      top: "var(--size-5)",
    },
  };
};

export const getDropdownBtnCssClass=(type: string, classes: any) => {
  if(type===BUTTON_TYPES.DROPDOWN_GRADIENT_BUTTON) {
    return `${classes[`${type}`]}`;
  }
  if(type===BUTTON_TYPES.DROPDOWN_BACKGROUND_BUTTON) {
    return `${classes[`${type}`]}`;
  }
  return classes.dropdownBtnCustom;
};

export const getToggleSwitchComponentStyle=(
  dragOffColor: string,
  borderColor: string,
  dragOnColor: string,
  gradientColor1: string,
  gradientColor2: string,
  bgColor: string
) => {
  return {
    "&.e-switch-wrapper .e-switch-handle ": {
      backgroundColor: dragOffColor? dragOffColor:"var(--primary-text)",
    },
    "&.e-switch-wrapper .e-switch-inner": {
      border: `var(--size-1) var(--border-solid) ${borderColor? borderColor:"var(--primary-text)"
        }`,
    },
    "&.e-switch-wrapper:not(.e-switch-disabled):hover .e-switch-handle:not(.e-switch-active)":
    {
      backgroundColor: dragOffColor? dragOffColor:"var(--primary-text)",
    },
    "&.e-switch-wrapper .e-switch-handle.e-switch-active": {
      backgroundColor: dragOnColor
        ? dragOnColor
        :"var(--surface-white) !important",
    },
    "&.e-switch-wrapper .e-switch-inner.e-switch-active,&.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on":
    {
      border: "var(--none)",
      background:
        gradientColor1&&gradientColor2
          ? `linear-gradient(100.44deg,  ${gradientColor1} 0%, ${gradientColor2} 110.4%)`
          :bgColor
            ? bgColor
            :`linear-gradient(100.44deg,  var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
    },
    "&.e-switch-wrapper.e-switch-disabled .e-switch-inner .e-switch-off,&.e-switch-wrapper.e-switch-disabled .e-switch-inner .e-switch-on":
    {
      background: "var(--light-blue)",
    },
    "&.e-switch-wrapper.e-switch-disabled .e-switch-inner": {
      border: "var(--none)",
    },
    "&.e-switch-wrapper.e-switch-disabled ": {
      cursor: "var(--not-allowed)",
    },
    ".e-switch-wrapper.e-switch-disabled:hover .e-switch-on": {
      background: "var(--light-blue)",
    },
    "&.e-switch-wrapper.e-switch-disabled .e-switch-handle": {
      background: "var(--surface-white)",
    },
    "& .e-switch-inner": {
      outline: 'none !important',

    },
    '&[aria-checked="true"]:active:before': {
      content: '""',
      position: "absolute",
      left: "var(--size-neg-3)",
      width: 'calc(100% + var(--size-6))',
      top: 'var(--size-neg-3)',
      height: 'calc(100% + var(--size-4))',
      border: 'var(--size-1) var(--border-solid) var(--primary-gradient-color1)',
    },
    '&[aria-checked="false"]:active:before': {
      content: '""',
      position: "absolute",
      left: "var(--size-neg-3)",
      width: 'calc(100% + var(--size-6))',
      top: 'var(--size-neg-3)',
      height: 'calc(100% + var(--size-4))',
      border: 'var(--size-1) var(--border-solid) var(--primary-text)',
    }
  };
};

export const getCheckBoxComponentStyle=(
  borderColor: string,
  borderColorFocus: string,
  bgColor: string,
  gradientColor1: string,
  gradientColor2: string
) => {
  return {
    display: "var(--display-inline-block)",
    //TODO: remove below css. this should not come inside the core component
    "& > label": {
      marginRight: "var(--size-10)",
      position: "var(--position-relative)",
      minHeight: "var(--size-24)",
      "&:active:before": {
        border: `var(--size-2) var(--border-solid) ${borderColorFocus? borderColorFocus:"var(--primary-gradient-color1)"
          }`,
        content: '""',
        width: "calc(100% + var(--size-7))",
        height: "calc(100% + var(--size-4))",
        top: "var(--size-neg-4)",
        left: "var(--size-neg-4)",
        position: "var(--position-absolute)",
      },
    },
    "& > label > input[type='checkbox']": {
      display: CONSTANTS.NONE,
    },

    "& > label > span.e-icons.e-frame": {
      height: "var(--size-20)",
      width: "var(--size-20)",
      border: `var(--size-2) var(--border-solid) ${borderColor? borderColor:"var(--primary-gradient-color1)"
        }`,
      borderRadius: "var(--size-2)",
      "&.e-check": {
        background:
          gradientColor1&&gradientColor2
            ? `linear-gradient(100.44deg,  ${gradientColor1} 0%, ${gradientColor2} 110.4%)`
            :bgColor
              ? bgColor
              :`linear-gradient(100.44deg,  var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
        border: CONSTANTS.NONE,
      },
      "&::before": {
        fontSize: "var(--size-12)",
        display: "var(--display-flex)",
        alignItems: "var(--align-center)",
        justifyContent: "var(--align-center)",
        height: '100%'
      },
    },
    "& > label:hover > span.e-icons.e-frame:not(.e-check)::before": {
      content: `"\\e935"`,
      color: "var(--natural-gray)",
    },
    "& .e-label": {
      lineHeight: "var(--size-16)",
      fontFamily: "Source Sans Pro !important",
    },
  };
};

export const getRadioButtonComponentStyle=(
  borderColor: string,
  borderColorFocus: string,
  bgColor: string,
  gradientColor1: string,
  gradientColor2: string
) => {
  return {
    display: "var(--display-flex)",
    alignItems: "var(--align-center)",
    "&:active:before": {
      border: `var(--size-2) var(--border-solid) ${borderColorFocus? borderColorFocus:"var(--primary-gradient-color1)"
        }`,
      borderRadius: "var(--size-2)",
      zIndex: "var(--size-neg-10)",
      content: '""',
      width: " calc(100% + var(--size-7))",
      height: " calc(100% + var(--size-4))",
      top: "var(--size-neg-4)",
      left: "var(--size-neg-4)",
      position: "var(--position-absolute)",
    },
    "& > label": {
      "& .e-label": {
        marginTop: "var(--size-neg-2)",
      },
      "&:before": {
        border: "var(--size-1) var(--border-solid) var(--primary-text)",
        borderRadius: "50%",
        boxSizing: "var(--border-box)",
        content: '""',
        height: "var(--size-20)",
        left: 0,
        position: "var(--position-absolute)",
        width: "var(--size-20)",
      },
      "&:after": {
        borderRadius: "50%",
        boxSizing: "var(--border-box)",
        content: '""',
        height: "var(--size-10)",
        left: "var(--size-5)",
        position: "var(--position-absolute)",
        top: "var(--size-5)",
        transform: "scale(0)",
        width: "var(--size-10)",
      },
    },
    "& input:checked ~ label:before": {
      borderColor: borderColor? borderColor:"var(--primary-gradient-color2)",
    },
    "& input:checked + label:hover:before,& input:checked + label:focus": {
      borderColor: borderColor? borderColor:"var(--primary-gradient-color2)",
    },
    "& input:checked ~ label:after": {
      background:
        gradientColor1&&gradientColor2
          ? `linear-gradient(100.44deg,  ${gradientColor1} 0%, ${gradientColor2} 110.4%)`
          :bgColor
            ? bgColor
            :`linear-gradient(100.44deg,  var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
      color: "var(--transparent)",
      border: 'none'
    },
    "& input:hover ~ label:before": {
      borderColor: "var(--primary-text)",
    },
    "& label .e-label": {
      lineHeight: "var(--size-24)",
      fontFamily: "Source Sans Pro !important",
    },
  };
};

export const getMultiSelectStyle=() => {
  return {
    border:
      "var(--size-1) var(--border-solid) var(--disabled-text-gray) !important",
    borderRadius: "var(--size-2) !important",
    "&:hover": {
      border:
        "var(--size-1) var(--border-solid) var(--primary-text) !important",
    },
    "&:active": {
      border:
        "var(--size-1) var(--border-solid) var(--primary-gradient-color1) !important",
    },
    "&.e-multiselect .e-multi-select-wrapper": {
      "& > .e-delim-view,& > .e-chips-close": {
        WebkitTextFillColor: "var(--primary-text)",
        color: "var(--primary-text)",
      },
    },

    "&.e-input-focus": {
      border:
        "var(--size-1) var(--border-solid) var(--primary-gradient-color1)!important",
      "& .e-multi-select-wrapper": {
        "& .e-chips-collection": {
          "& .e-chips": {
            background: "var(--button-light) !important",
            "& .e-chipcontent,& .e-chips-close": {
              WebkitTextFillColor: "var(--primary-text)",
              color: "var(--primary-text) !important",
            },
            "&:hover": {
              background: "var(--light-blue) !important",
            },
          },
        },
      },
    },
    "&.e-disabled": {
      border: "var(--none) !important",
      background: 'var(--button-light) !important',
      borderRadius: "var(--size-2) !important",
    },
  };

};

export const getParentProfileStyle=({overlapRadius=true, type=CONSTANTS.PROFILE}) => {
  return {
    position: "var(--position-relative)",
    alignItems: "center",
    padding: type==="org"? '9px':"unset",
    '& .labelName': {
      marginTop: "var(--size-0)",
    },
    '& .editIcon': {
      display: 'none',
    },
    '& .profile-root-wrapper': {

    },
    '& .profile-wrap': {
      display: "inline-block",
      position: "var(--position-relative)",
      overflow: "hidden",
      // borderRadius: overlapRadius ? "100%" : "none",
      height: type==="profile"? 'var(--size-100)':"var(--size-75)",
      '& img': {
        objectFit: "cover",
      },
      '&:hover': {
        '& .deleteImgContainer': {
          width: type==="org"? "35px":"var(--size-50)",
          backgroundColor: 'rgba(255, 255, 255, 0.65)',
          height: type==='org'? "85px":"var(--size-100)",
          borderRadius: overlapRadius? "var(--size-0) var(--size-150) var(--size-150) var(--size-0)":'none',
          display: "var(--display-flex)",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "var(--size-0)",
          right: "var(--size-0)",
          // left: type === "org" ? "var(--size-50)" : "unset",
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
          }
        },

        '& .editImgContainer': {
          width: type==="org"? "40px":"var(--size-50)",
          backgroundColor: 'rgba(255, 255, 255, 0.65)',
          height: type==='org'? "85px":"var(--size-100)",
          borderRadius: overlapRadius? "var(--size-150) var(--size-0) var(--size-0) var(--size-150)":"none",
          display: "var(--display-flex)",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "0px",
          left: "0px",
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
          }
        },

        '& .editIcon': {
          display: 'block',
          cursor: "pointer",
        },
      },
    },

  }
}
export const getRichTextEditorStyle=(
  toolbarBgColor: string,
  iconColor: string,
  iconSelectedGradientColor1: string,
  iconSelectedGradientColor2: string,
  iconHoverBgColor: string,
  iconHoverColor: string,
  readonly: any,
  contentHeight: string,
  padding : any
) => {
  return {
    '&.e-rte-readonly': {
      '& .e-content': {
        color: 'var(--primary-text) !important',
        overflowWrap: 'break-word',
      },
      '& .e-rte-container' : {
        border : 'none'
      }
    },
    '& .e-rte-content .e-content': {
      padding: padding? padding : readonly? "0px !important":"16px",
      height: contentHeight && `${contentHeight}px`,
    },
    '& .e-content': {
      minHeight: `${readonly? 'auto':'100px'} !important`
    },
    borderRadius: "var(--size-4)",
    '& .e-rte-toolbar,& .e-toolbar-wrapper': {
      borderBottom: '#c6d0df !important',
      borderBottomStyle: 'solid !important',
      borderBottomWidth: '1px !important',
    },
    "& > .e-rte-content": {
      borderBottomRightRadius: "var(--size-3)",
      borderBottomLeftRadius: "var(--size-3)",
    },
    "& .e-toolbar-wrapper": {
      "& .e-toolbar": {
        borderRadius: "var(--size-3) !important",
        "& .e-expended-nav": {
          borderTopRightRadius: "var(--size-3) !important",
        },
      },
      "& .e-toolbar, & .e-extended-toolbar .e-toolbar-pop": {
        background: toolbarBgColor? toolbarBgColor:`var(--button-light)`,
        "& .e-toolbar-items": {
          background: "var(--transparent)",
          "& .e-icons": {
            color: `${iconColor? iconColor:"var(--disabled-text-gray)"
              } !important`,
          },
          "& .e-toolbar-popup": {
            background: "var(--transparent)",
          },
          "& .e-rte-dropdown-btn": {
            "& .e-rte-dropdown-btn-text": {
              color: `${iconColor? iconColor:"var(--primary-text) "
                } !important`,
            },
          },
          "& .e-toolbar-item.e-active": {
            "& .e-tbar-btn": {
              background: "var(--transparent)",
              "& .e-icons": {
                background: ` ${iconSelectedGradientColor1&&iconSelectedGradientColor2
                  ? `linear-gradient(100.44deg, ${iconSelectedGradientColor1} 0%, ${iconSelectedGradientColor2} 110.4%)`
                  :"linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)"
                  } `,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "var(--transparent)",
              },
            },
          },
        },

        "& .e-overlay": {
          background: "var(--transparent)",
          "& .e-icons": {
            color: "var(--light-black) !important",
            opacity: "0.5",
          },
          "& .e-toolbar-item.e-active": {
            "& .e-tbar-btn": {
              background: "var(--transparent)",
              "& .e-icons": {
                background: ` ${iconSelectedGradientColor1&&iconSelectedGradientColor2
                  ? `linear-gradient(100.44deg, ${iconSelectedGradientColor1} 0%, ${iconSelectedGradientColor2} 110.4%)`
                  :"linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)"
                  } `,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "var(--transparent)",
              },
            },
          },
        },
        "& .e-toolbar-popup": {
          "& .e-icons": {
            color: `${iconColor? iconColor:"var(--disabled-text-gray)"
              } !important`,
          },
        },
        "& .e-tbar-btn": {
          background: "var(--transparent)",
          minHeight: "var(--size-24) !important",
          minWidth: "var(--size-24) !important",
          borderRadius: "var(--size-4) !important",
          "& span": {
            fontSize: "var(--size-12) !important",
          },
          "&:hover": {
            background: iconHoverBgColor
              ? iconHoverBgColor
              :"var(--surface-white)",
            "& .e-icons": {
              color: `${iconHoverColor? iconHoverColor:"var(--primary-text)"
                } !important`,
            },
          },
        },
      },
    },
  };
};

export const getSizeClass=(size: string) => {
  switch(size) {
    case 'XXS': return "e-avatar-xxsmall";
    case 'LSM': return "e-avatar-little-small";
    case 'XS': return "e-avatar-xsmall";
    case 'SM': return "e-avatar-small";
    case 'LG': return "e-avatar-large";
    case 'LLG': return "e-avatar-little-large";
    case 'XL': return "e-avatar-xlarge";
    default: "e-avatar-small"
  }
};

export const getDropdownCommonCss=() => {
  return {
    background: "var(--surface-white) !important",
    color: "var(--primary-text) !important",
  };
};

export const getAvatarStyle=(bgColor: string) => {
  return {
    "& img": {
      height: "106%",
    },
    "&.e-avatar-square-organization": {
      boxShadow: 'var(--size-0) var(--size-0) var(--size-2) var(--size-0-dot-five) var(--box-shadow)',
    },
    "& span": {
      fontWeight: "var(--font-weight-600)",
    },
    "&.e-avatar-xxsmall": {
      width: "var(--size-20)",
      height: "var(--size-20)",
      "& span": {
        fontSize: "var(--size-9)",
      },
    },
    "&.e-avatar-xsmall": {
      width: "var(--size-24)",
      height: "var(--size-24)",
      '&.e-avatar-square-portal': {
        borderRadius: "var(--size-4)",
      },
      '&.e-avatar-square-organization': {
        borderRadius: "var(--size-3)",
      },
      "& span": {
        fontSize: "var(--size-12)",
      },
    },
    "&.e-avatar-little-small": {
      width: "var(--size-30)",
      height: "var(--size-30)",
      "& span": {
        fontSize: "var(--size-16)",
      },
    },
    "&.e-avatar-small": {
      width: "var(--size-36)",
      height: "var(--size-36)",
      '&.e-avatar-square-portal': {
        borderRadius: "var(--size-6)",
      },
      '&.e-avatar-square-organization': {
        borderRadius: "var(--size-1)",
      },
      "& span": {
        fontSize: "var(--size-20)",
      },
    },
    "&.e-avatar-large": {
      width: "var(--size-48)",
      height: "var(--size-48)",
      '&.e-avatar-square-portal': {
        borderRadius: "var(--size-8)",
      },
      '&.e-avatar-square-organization': {
        borderRadius: "var(--size-2)",
      },
      "& span": {
        fontSize: "var(--size-26)",
      },
    },
    "&.e-avatar-little-large": {
      width: "var(--size-75)",
      height: "var(--size-75)",
      '&.e-avatar-square-portal': {
        borderRadius: "var(--size-12)",
      },
      '&.e-avatar-square-organization': {
        borderRadius: "var(--size-3)",
      },
      "& span": {
        fontSize: "var(--size-32)",
      },
    },
    "&.e-avatar-xlarge": {
      width: "var(--size-100)",
      height: "var(--size-100)",
      '&.e-avatar-square-portal': {
        borderRadius: "var(--size-16)",
      },
      '&.e-avatar-square-organization': {
        borderRadius: "var(--size-6)",

      },
      "& span": {
        fontSize: "var(--size-56)",
      },
    },
    backgroundColor: `${bgColor? bgColor:'var(system-colour-9)'} !important`,
    "&.e-avatar": {
      fontFamily: "Source Sans Pro !important",
    },
  };
};

export const getAvatarGroupStyle=() => {
  return {
    display: "var(--display-flex)",
    gap: "var(--size-3)",
    justifyContent: "flex-start",
    "& .e-avatar:not(:first-child)": {
      marginLeft: "-12px",
    },
  };
};

export const tableStyle=(
  background: string
) => {
  return {
    "& .e-headercontent": {
      "& .e-columnheader": {
        "& .e-headercell": {
          background: background,
          borderBottom: "1px solid #ecf3fe !important",
          paddingLeft: "22px",
        },
      },
    },
    "& .e-content": {
      overflow: "hidden !important"
    },
    "& .e-gridheader": {
      border: "none !important",
    },
    "& .e-headercelldiv": {
      color: "var(--primary-text) !important",
      fontSize: "14px",
      fontWeight: "600",

    },
    "& .e-rowcell": {
      fontSize: "12px",
      borderColor: "#ecf3fe",
      paddingLeft: "23px !important"
    },
  }
}

export const getCardStyle=(
  width: any,
  background: any,
  textColor: any
) => {
  return {
    width,
    background: "linear-gradient(111deg, var(--surface-white) 45%, var(--white-opacity) 129%)",
    boxShadow: "var(--size-4) var(--size-4) var(--size-15) var(--size-0) var(--card-box-shadow)",
    borderRadius: "var(--size-8)",
    backgroundImage: "linear-gradient(132deg, #fff, rgba(255, 255, 255, 0.5) 100%)",
    "& > .e-card-header": {
      background: "rgba(209, 223, 244, 0.5)",
      padding: "0 !important",
      width: "100% !important",
      "& .e-card-header-content-wrapper": {
        display: "var(--display-flex)",
        justifyContent: "var(--justify-space-between)",
        padding: "var(--size-11) var(--size-20) var(--size-11) var(--size-20)",
        "& .e-card-header-title": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontStyle: "var(--font-style-normal)",
          fontWeight: "var(--font-weight-bold)",
          fontSize: "var(--size-14)",
          lineHeight: "var(--size-18)",
          color: textColor,
          "& .e-card-header-sub-title": {
            color: "var(--soft-gray)",
          },
        },
        "& .e-card-header-link": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontStyle: "var(--font-style-normal)",
          fontWeight: "var(--font-weight-700)",
          fontSize: "var(--size-14)",
          lineHeight: "var(--size-18)",
          background: `linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "var(--transparent)",
          backgroundClip: "text",
          paddingRight: "var(--size-10)",
        },
        "& .e-card-header-share": {
          marginLeft: "16px",
          display: "flex",
          alignSelf: "end"
        },
        "& .e-card-header-max": {
          marginRight: "var(--size-18)",
        },
        "& .e-card-header-ellipcise": {
          padding: "var(--size-0)",
          background: "white",
          border: "none",
          display: "flex"
        }
      },
    },
    "&.e-card, &.e-card:hover": {
      border: "var(--none) !important",
      padding: "0",
      flexBasis: "var(--size-310)",
      flexShrink: "1",
    },

    "& > .e-card-content": {
      display: 'flex',
      padding: 'var(--size-0)',
      height: "100%",
      alignItems: "flex-start",
      background: background,
      color: textColor,
    },
  };
};

export const getFeedbackToastStyle=(title: string) => {
  return {
    zIndex: "999999999 !important",
    background: "var(--surface-white) !important",
    border: "1.11111px var(--border-solid) var(--surface-neutral) !important",
    boxShadow: "0px 0px 17.7778px 2.22222px var(--box-shadow) !important",
    borderRadius: "var(--size-6) !important",
    alignItems: "var(--align-center)",
    marginBottom: 'var(--size-50)',
    marginRight: 'var(--size-40)',
    '&.e-toast-container .e-toast': {
      margin: '0 !important',
      padding: '34px !important',
    },
    "& .e-toast-message": {
      display: "var(--display-grid) !important",
      gridTemplateAreas: title
        ? "'heading button' 'subheading button'"
        :"'subheading button'",
      alignItems: "var(--align-center)",
      "& .e-toast-title": {
        gridArea: "heading",
      },
      "& .e-toast-content": {
        gridArea: "subheading",
        padding:
          "var(--size-0) var(--size-15) var(--size-0) var(--size-0) !important",
        "& .toastContent": {
          display: "var(--display-flex)",
          alignItems: "var(--align-center)",
        },
      },
      "& .e-toast-actions": {
        gridArea: "button",
        padding:
          "var(--size-0) var(--size-15) var(--size-0) var(--size-0) !important",
        "& button": {
          whiteSpace: "nowrap !important",
          background: "var(--transparent) !important",
          border: "var(--none) !important",
          boxShadow: "var(--none) !important",
          color: "var(--text-light) !important",
          fontFamily: "Source Sans Pro !important",
          fontStyle: "var(--font-style-normal) !important",
          fontWeight: "var(--font-weight-600) !important",
          fontSize: "var(--size-14) !important",
          marginLeft: "30px !important",
          padding: "6px !important"
        },
      },
    },
    "&.e-toast": {
      "& .e-icons": {
        fontSize: "var(--size-11)",
        color: "var(--primary-text) !important",
        "&.e-toast-icon": {
          "&:before": {
            width: "var(--size-30) !important",
            height: "var(--size-30) !important",
            backgroundSize: "var(--size-24)",
            backgroundPosition:
              "var(--bg-position-center) var(--bg-position-center)",
            backgroundRepeat: "var(--background-no-repeat)",
            content: "''",
          },
          "&.e-toast-error-icon:before": {
            backgroundImage: "url('/assets/icons/svg/toastError.svg')",
          },
          "&.e-toast-info-icon:before": {
            backgroundImage: "url('/assets/icons/svg/toastInfo.svg')",
          },
          "&.e-toast-warning-icon:before": {
            backgroundImage: "url('/assets/icons/svg/toastWarning.svg')",
          },
          "&.e-toast-success-icon:before": {
            backgroundImage: "url('/assets/icons/svg/toastSuccess.svg')",
          },
        },
      },
    },
    "&.e-toast-success": {
      "& > .e-toast-progress": {
        background: "var(--positive-communication) !important",
      },
    },
    "&.e-toast-warning": {
      "& > .e-toast-progress": {
        background: "var(--warning) !important",
      },
    },
    "&.e-toast-info": {
      "& > .e-toast-progress": {
        background: "var(--primary-gradient-color2) !important",
      },
    },
    "&.e-toast-error": {
      "& .e-toast-progress": {
        background: "var(--negative-communication) !important",
      },
    },
    "& > .e-toast-progress": {
      height: "var(--size-8) !important",
      borderRadius: "var(--size-5)",
    },
  };
};

export const getDialogModalStyle=(headerBgColor: string) => {
  return {
    background:
      "linear-gradient(135deg, var(--surface-white) 44.5%, var(--light-gray) 129%)",
    boxShadow: "var(--size-4) var(--size-8) var(--size-8) rgba(0, 0, 0, 0.15)",
    borderRadius: "var(--size-6)",
    outline: "var(--none)",
    overflow: "auto",
    "& > :nth-child(1)": {
      background: headerBgColor,
    },
    "& .e-dlg-header-content": {
      padding: "var(--size-40) var(--size-40) var(--size-15) var(--size-40)",
      "& .e-dlg-header": {
        width: "100% !important",
      },
      "& .e-dlg-header *": {
        fontFamily: "Source Sans Pro",
        fontStyle: "var(--font-style-normal)",
        fontWeight: "var(--font-weight-600) !important",
        fontSize: "var(--size-24) !important",
        lineHeight: "var(--size-30) !important",
        color: "var(--primary-text) !important",
      },
    },
    "& .e-dlg-content": {
      padding: "var(--size-0) var(--size-40) var(--size-40) var(--size-40)",
      "& .textComponentWrap": {
        paddingBottom: " var(--size-40)",
        "& ~ .formComponentWrap": {
          paddingTop: "var(--size-0)",
        },
      },
      "& .formComponentWrap": {
        paddingTop: "var(--size-25)",
      },
      "& .dialogTextContent": {
        fontFamily: "Source Sans Pro",
        fontStyle: "var(--font-style-normal)",
        fontWeight: "var(--font-weight-400) !important",
        fontSize: "var(--size-14) !important",
        lineHeight: "var(--size-18) !important",
        color: "var(--primary-text) !important",
      },
    },
    "& .e-footer-content": {
      padding:
        "var(--size-0) var(--size-40) var(--size-40) var(--size-40) !important",
    },
  };
};

export const getDialogFooterBtnStyle=() => {
  return {
    display: "var(--display-flex)",

    "& .e-btn": {
      marginRight: "var(--size-16) !important",
    },
  };
};

export const getDialogHeaderStyle=() => {
  return {
    display: "var(--display-flex)",
    justifyContent: "var(--justify-space-between)",
    width: "100%",
  };
};

export const getTooltipStyle=(
  bgColor: string,
  fontSize: string,
  color: string,
  tipColor: string
) => {
  return {
    background: `${bgColor? bgColor:"var(--primary-text)"} !important`,
    border: "var(--none) !important",
    "& .e-tip-content": {
      fontFamily: "Source Sans Pro !important",
      fontStyle: "var(--font-style-normal)",
      fontWeight: "var(--font-weight-400)",
      fontSize: `${fontSize? fontSize:"var(--size-14) !important"}`,
      color: `${color? color:"var(--surface-white) !important"}`,
      width: "auto !important",
      height: '100%',
      lineHeight: 'var(--size-22) !important',
      paddingTop: 'var(--size-8) !important',
      paddingBottom: 'var(--size-8) !important',
      paddingLeft: 'var(--size-16) !important',
      paddingRight: 'var(--size-16) !important',
    },
    "& .e-arrow-tip": {
      "& .e-arrow-tip-inner:before": {
        content: '""',
      },
      "& > .e-arrow-tip-outer, & > .e-arrow-tip-inner": {
        "&.e-tip-top": {
          borderBottom: `var(--size-8) var(--border-solid) ${tipColor? tipColor:"var(--primary-text) !important"
            }`,
          color: `${tipColor? tipColor:"var(--primary-text) !important"}`,
        },
        "&.e-tip-bottom": {
          borderTop: `var(--size-8) var(--border-solid) ${tipColor? tipColor:"var(--primary-text) !important"
            }`,
          color: `${tipColor? tipColor:"var(--primary-text) !important"}`,
        },
        "&.e-tip-right": {
          borderLeft: `var(--size-8) var(--border-solid) ${tipColor? tipColor:"var(--primary-text) !important"
            }`,
          color: `${tipColor? tipColor:"var(--primary-text) !important"}`,
        },
        "&.e-tip-left": {
          borderRight: `var(--size-8) var(--border-solid) ${tipColor? tipColor:"var(--primary-text) !important"
            }`,
          color: `${tipColor? tipColor:"var(--primary-text) !important"}`,
        },
      },

    },
  };
};

export const dropDownCommon=() => {
  return {
    paddingLeft: "var(--size-10)",
    paddingRight: "var(--size-13) !important",
    "&.e-popup-open": {
      paddingLeft: "0 !important",
    },
  };
};
export const getDropDownGradientButtonStyle=() => {
  return {
    ...dropDownCommon(),
    background: `linear-gradient(100.44deg,  var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%) !important`,
    color: `var(--surface-white) !important`,
    "&.e-dropdown-popup.e-popup.e-control": getDropdownCommonCss(),
    borderRadius: "var(--size-2)",
    "&:hover,&:focus,&.e-active": {
      color: `var(--surface-white)`,
    },
    "&:disabled": {
      background: `var(--button-light) !important`,
      color: `var(--disabled-text-gray) !important`,
      cursor: "not-allowed !important"
    },

    "& .e-caret": {
      marginLeft: "var(--size-10)",
      '&:before, .e-dropdown-btn.e-btn .e-caret::before': {
        fontSize: '13px',
        display: 'flex',
        justifyContent: 'flex-end',
        // marginTop: '4px',
      }
    },
  };
};

export const getDropDownBackgroundButtonStyle=(args) => {
  return {
    ...dropDownCommon(),
    width: args.width??"",
    background: `var(--button-light)`,
    color: `var(--primary-text)`,
    '&.e-dropdown-popup ul': {
      width: args.popUpWidth??"",
      '& li': {
        alignItems: "center",
      }
    },
    "&.e-dropdown-popup.e-popup.e-control": getDropdownCommonCss(),
    borderRadius: "var(--size-5)",
    "&:hover": {
      background: `var(--button-light)`,
      color: `var(--primary-text)`,
    },
    "&:focus": {
      background: `var(--button-light)`,
      color: `var(--primary-text)`,
      outline: "none",
    },
    "&:disabled": {
      background: `var(--button-light) !important`,
      color: `var(--disabled-text-gray) !important`,
      cursor: "not-allowed !important"
    },
    "& .e-caret": {
      marginLeft: "var(--size-10)",
    },
  };
};

export const getDropDownCustomButtonStyle=(args) => {
  return {
    ...dropDownCommon(),
    "&.e-dropdown-popup.e-popup.e-control": getDropdownCommonCss(),
    background: `transparent !important`,
    '&.e-dropdown-popup ul': {
      width: args.popUpWidth??"",
      '& li': {
        alignItems: "center",
      }
    },
    padding:
      "var(--size-2) var(--size-13) var(--size-2) var(--size-13) !important",
    "& .e-icons.e-icon-right.e-caret": {
      marginLeft: "var(--size-10)",
      marginTop: 0,
    },
    "&.e-dropdown-popup": {
      background: "var(--surface-white) !important",
    },
    "& .e-caret": {
      display: args.hideIcon? 'none':""
    },
  };
};

export const getSliderParentWrapperStyle=() => {
  return {
    "& .e-slider-hover .e-slider-track": {
      background: "var(--soft-gray) !important",
    },
    '& .e-control-wrapper.e-slider-container.e-horizontal': {
      display: 'flex'
    }
  };
};

export const getSliderStyle=(
  gradientColor1: string,
  gradientColor2: string,
  color: string,
  handleShadowColor: string,
  handleGradientColor1: string,
  handleGradientColor2: string
) => {
  return {
    "&.e-slider": {
      "& .e-range": {
        background:
          gradientColor1&&gradientColor2
            ? `linear-gradient(100.44deg, ${gradientColor1}  0%, ${gradientColor2} 110.4%) !important`
            :color,
      },
      "& .e-handle": {
        border: "var(--none) !important",
        boxShadow: handleShadowColor
          ? `${handleShadowColor}45 var(--size-0) var(--size-0) var(--size-0) var(--size-4)`
          :"var(--primary-gradient-color2-opacity) var(--size-0) var(--size-0) var(--size-0) var(--size-4)",
        transition: "transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0s",
        background: `linear-gradient(100.44deg, ${handleGradientColor1}  0%, ${handleGradientColor2} 110.4%) !important`,
      },
      "& .e-slider-track": {
        borderRadius: "var(--size-10)",
        "&:hover": {
          background: "var(--soft-gray) !important",
        },
      },
    },
  };
};

export const getLocationList=(locations: any) => {
  const parseLocation=JSON.parse(locations)
  return parseLocation.map((s: any, index: any) => {
    return {
      id: Date.now()+index,
      name: s.name,
      formName: "location"+(index+1),
      errorMsz: ""
    }
  })
}

const getProfileErrorStyle=() => {
  return {
    display: 'flex',
    alignItems: 'center',
    color: "var(--negative-communication)",
    fontSize: "var(--size-12)",
    marginTop: "var(--size-2)",
    '& .mr-5': {
      marginRight: "var(--size-5)"
    }
  }
}


export const getProfileCropControls=() => {
  return {
    marginTop: "var(--size-30)",
    marginBottom: "var(--size-30)",
    display: "var(--display-flex)",
    alignItems: 'center',
    justifyContent: 'space-around',
    '& label': {
      marginRight: "var(--size-15)",
      fontFamily: "Source Sans Pro !important",
      fontSize: "var(--size-14)",
      fontWeight: 'var(--font-weight-600)',
      fontStyle: 'var(--font-style-normal)',

      color: 'var(--primary-text)',
    },
    '& .sliderWrapper [class*="parentWrapper"]': {
      display: "var(--display-flex)",
      alignItems: "center"
    }
  }
}

export const getCanvasToFile=(image: any, cropProfilePic: any,
  cropRotate: any,
  cropScale: any,) => {
  if(image) {
    const TO_RADIANS=Math.PI/180

    const documentDOM: any=document as any;
    const canvas: any=documentDOM.createElement('canvas');
    const ctx=canvas.getContext('2d')

    if(!ctx) {
      throw new Error('No 2d context')
    }

    const scaleX=image?.naturalWidth/image?.width
    const scaleY=image?.naturalHeight/image?.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio=window.devicePixelRatio
    // const pixelRatio = 1
    let width: any;
    let height: any;
    let xAxis: any;
    let yAxis: any;

    if(cropProfilePic.unit==="px") {
      width=cropProfilePic.width;
      height=cropProfilePic.height;
      xAxis=cropProfilePic.x;
      yAxis=cropProfilePic.y;
    }
    if(cropProfilePic.unit==="%") {

      width=(image?.width/100)*cropProfilePic.width;
      height=(image?.height/100)*cropProfilePic.height;
      xAxis=(image?.width/100)*cropProfilePic.x;
      yAxis=(image?.height/100)*cropProfilePic.y;
    }
    canvas.width=Math.floor(width*scaleX*pixelRatio)
    canvas.height=Math.floor(height*scaleY*pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality='high'

    const cropX=xAxis*scaleX
    const cropY=yAxis*scaleY

    const rotateRads=cropRotate*TO_RADIANS
    const centerX=image?.naturalWidth/2
    const centerY=image?.naturalHeight/2

    ctx.save()

    ctx.translate(-cropX, -cropY)
    ctx.translate(centerX, centerY)
    ctx.rotate(rotateRads)
    ctx.scale(cropScale, cropScale)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
      image,
      0,
      0,
      image?.naturalWidth,
      image?.naturalHeight,
      0,
      0,
      image?.naturalWidth,
      image?.naturalHeight,
    )

    return canvas;
  } else {
    return null
  }
}

export const getCroppedImgInBase64=(image: any, cropProfilePic: any,
  cropRotate: any,
  cropScale: any,) => {

  const canvas=getCanvasToFile(image, cropProfilePic, cropRotate, cropScale)
  // As Base64 string
  const base64Image: any=canvas?.toDataURL('image/jpeg');
  // // As a blob
  const blob=new Promise((resolve, reject) => {
    canvas.toBlob((file: any) => {
      file.name="profile.jpeg";
      resolve(file);
    }, 'image/jpeg');
  });

  return {base64Image, blob}
}

export const getCroppedImgInBlob=(image: any, cropProfilePic: any,
  cropRotate: any,
  cropScale: any,) => {
  const canvas=getCanvasToFile(image, cropProfilePic, cropRotate, cropScale)

  if(canvas) {
    // // As a blob
    return canvas.toBlob((file: any) => {
      file.name="profile.jpeg";
    }, 'image/jpeg');
  }

}

export const getDropdownListCssClass=(type: string, classes: any) => {
  if(type) {
    return `${classes[`${type}`]}`;
  }
  return classes.dropdownBackgroundList;
};

export const getSignStatusInitialUserInformation=(fileVersionId: string, resourceType: string, userDetails: any, projectData: any) => {
  return {
    name: `${userDetails?.firstName} ${userDetails?.lastName}`,
    userId: userDetails?.id,
    projectId: projectData?.id,
    signType: SignTypeValues.ATTACH_SIGNED_DOCUMENT,
    signDoc: "",
    comment: "",
    fileVersionId: fileVersionId,
    resourceType: resourceType,
    prevStatus: "",
    status: "",
    error: {
      signDoc: "",
      comment: "",
      status: ""
    }
  }
}

export const getFormattedFileStatus=(statusName: string) => {
  return statusName?.charAt(0).toUpperCase()+statusName?.substring(1).toLocaleLowerCase().replace(/_/g, " ");
}

export const getNameInitials=(userData: any) => {
  let initial=userData?.firstName&&userData?.firstName?.[0]?.toUpperCase();
  if(userData.lastName&&userData.lastName?.length>0) {
    initial+=userData?.lastName?.[0]?.toUpperCase();
  }
  return initial;
}

export const getDropDownStyle=(
  width: string,
) => {
  return {
    "& .dropdown-content": {
      width: width,
      borderRadius: "2px",
      boxShadow: "2px 2px 4px 0 rgba(25, 32, 72, 0.15)",
      border: "solid 1px #ecf3fe",
      background: "#fff",
      position: "fixed",
      marginLeft: "-160px",
      marginTop: " 25px",
      zIndex: "99999",
      overflowX: "hidden",
      overflowY: "hidden",
    },
  };
}

export const getUrlForImage=(data: any, type="profile") => {
  //TODO: remove data.now as it is causing infinite rerneder in some cases.
  return `${process.env.REACT_APP_CUBE_BACKEND
    }/fileSystemServer/getImage/${type}/${data.id}?${Date.now()}`;
};

export function isMergeAllowed(fileObjArray: any[]) {
  let fileSettings = {
    mergedAllowed: true,
    isFile: true
  };

  let hasEstimate = false;
  let hasFile = false;

  for (let i = 0; i < fileObjArray.length; i++) {
    const fileObj = fileObjArray[i];

    if (fileObj.resourceType === "RESOURCE_TYPE_MERGED_FILE") {
      if (hasEstimate) {
        fileSettings.mergedAllowed = false;
        break;
      }
      hasFile = true;
    }

    if (fileObj.resourceType === "RESOURCE_TYPE_ESTIMATE") {
      fileSettings.isFile = false;
      hasEstimate = true;
    } else {
      if (!['CAD', 'BIM'].includes(fileObj.engineType)) {
        fileSettings.mergedAllowed = false;
        break;
      }
      hasFile = true;
    }
  }

  if (hasEstimate && hasFile) {
    fileSettings.mergedAllowed = false;
  }

  return fileSettings;
}


export const conversionStatusTemplate=(params: {conversionStatus: string;}) => {
  let name; let tooltip;

  if(params.conversionStatus=='NOT_STARTED'||params.conversionStatus=='RETRY') {
    name='FileConversionStatusNotStarted';
    if(params.conversionStatus=='NOT_STARTED') {
      tooltip='Not Started';
    }
    else {
      tooltip='Retry';
    }
  }
  else if(params.conversionStatus=='IN_PROGRESS') {
    name='FileConversionStatusInProgress';
    tooltip='In Progress';
  }
  else if(params.conversionStatus=='COMPLETED') {
    name='FileConversionStatusSuccessfull';
    tooltip='Successful';
  }
  else if(params.conversionStatus=='ERRORED') {
    name='FileConversionStatusUnsuccessfull';
    tooltip='Failed';
  }
  else if(params.conversionStatus=='NOT_REQUIRED') {
    name='FileConversionStatusNotRequired';
    tooltip='Conversion Not Required';
  }
  else {
    return ('');
  }
  return (
    <div style={{width: '16px'}}>
      <ToolTipComponent position="BottomCenter" content={tooltip}>
        <IconComponent name={name} />
      </ToolTipComponent>
    </div>
  );
 
};

export const getTaskId = (taskData:Object) => {
  let taskId;
  if (taskData?.WBSCode) {
    taskId = `${taskData?.WBSCode}`;
  } else if (taskData?.customTaskId) {
    taskId = `${taskData?.customTaskId}`;
  } else {
    const counter = taskData?.scheduleCounter ?? "0";
    taskId = `TSK-${String(counter).padStart(4, "0")}`;
  }
  return taskId;
};