const { folderRoles } = require("../server/roles/roles.constant")

const getPermissionConstants = () => {
  const permissions = folderRoles()
  const obj = {}

  Object.values(permissions).map((role) => {
    obj[role.code] = role.id
  })
  return obj
}

module.exports = {
  getPermissionConstants
};
