import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { getNameInitials } from "../constants/globalFunctions";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../constants/globalVariable";
import { AvatarGroup } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchUserTagData } from "../../actions/setUserTagData";

const StyledAvatar = styled(Avatar)(({ size }) => ({
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "&.MuiAvatar-root": {
    fontSize: size === "XXS" ? "0.5rem" : size === "XS" ? "0.5rem" : "1rem",
  },
}));

const getSizeClass = (size = "SM") => {
  switch (size) {
    case "XXS":
      return {
        width: "var(--size-20)",
        height: "var(--size-20)",
      };
    case "XS":
      return {
        width: "var(--size-24)",
        height: "var(--size-24)",
      };
    case "LSM":
      return {
        width: "var(--size-30)",
        height: "var(--size-30)",
      };
    case "SM":
      return {
        width: "var(--size-36)",
        height: "var(--size-36)",
      };
    case "LG":
      return {
        width: "var(--size-48)",
        height: "var(--size-48)",
      };
    case "LLG":
      return {
        width: "var(--size-75)",
        height: "var(--size-75)",
      };
    case "XL":
      return {
        width: "var(--size-100)",
        height: "var(--size-100)",
      };
    case "GR":
      return {
        width: "var(--size-40)",
        height: "var(--size-40)",
      };
    default:
      return {
        width: "var(--size-36)",
        height: "var(--size-36)",
      };
  }
};

function AvatarComponent({
  isCircle = false,
  isSquare = false,
  size = "SM",
  bgColor = "var(--system-colour-2)",
  style,
  id,
  maxAvatars,
  groupedAvatars,
}) {
  const [error, setError] = useState(false);
  const variant = isSquare ? "square" : isCircle ? "circular" : "circular";
  const [avatarProps, setAvatarProps] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchUserTagData(id));
    }
  }, [id]);

  const { userTagData } = useSelector((state) => state);

  let profilePicKey;

  useEffect(() => {
    if (id && userTagData[id]?.data?.profilePic && !error) {
      setAvatarProps({
        src: `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getImage/${CONSTANTS.PROFILE}/${id}`,
        onError: () => setError(true),
      });
    } else {
      setAvatarProps({});
    }
  }, [id, userTagData]);

  useEffect(() => {
    if (userTagData[id]?.data?.profilePicChanged) {
      setAvatarProps({
        src: `${userTagData[id].data.profilePic}`,
      });
    }
  }, [userTagData[id]?.data?.profilePicChanged]);

  const avatarStyle = {
    backgroundColor: bgColor,
    ...getSizeClass(size),
    ...style,
    // border: "0",
  };

  return (
    <>
      {userTagData[id] && userTagData[id].data && userTagData[id].isLoaded && (
        <StyledAvatar
          variant={variant}
          style={avatarStyle}
          size={size}
          {...avatarProps}
        >
          {(!profilePicKey || error) &&
            getNameInitials({
              firstName: userTagData[id].data.firstName,
              lastName: userTagData[id].data.lastName,
            })}
        </StyledAvatar>
      )}
      {maxAvatars && (
        <AvatarGroup
          variant="circular"
          max={maxAvatars}
          renderSurplus={(surplus) => {
            if (surplus >= 1000) {
              return <span>+{surplus.toString()[0]}k</span>;
            }
            return `+${surplus}`;
          }}
          slotProps={{
            additionalAvatar: {
              sx: {
                ...avatarStyle,
                fontSize: "0.75rem",
                border: "2px solid #fff",
              },
            },
          }}
        >
          {groupedAvatars}
        </AvatarGroup>
      )}
    </>
  );
}

export default AvatarComponent;
