import { DataManager, WebMethodAdaptor } from "@syncfusion/ej2-data";

// WebMethodAdaptor.prototype.beforeSend = (_, request) => {
//   request.withCredentials = true;
// };

class CustomAdaptor extends WebMethodAdaptor {
  constructor() {
    super(...arguments);
    this.customProcessResponse = arguments[0];
  }

  processResponse(data, instance, value, httpRequest, request, changes) {
    let originalValue = super.processResponse.apply(this, arguments);
    if (changes != null) {
      originalValue = changes;
    }
    const result = this.customProcessResponse(originalValue);
    return result;
  }
}

export const getDataManagerObj = (url, processResponse) => {
  let adaptor = new WebMethodAdaptor();

  if (processResponse) {
    adaptor = new CustomAdaptor(processResponse);
  }

  const dataManager = new DataManager({
    url,
    adaptor,
  });

  dataManager.adaptor.beforeSend = (_, request, settings) => {
    settings.fetchRequest = new Request(settings.url, {
      method: settings.fetchRequest.method,
      body: settings.data,
      headers: { "Content-Type": settings.contentType },
      credentials: "include",
    });
  };

  return dataManager;
};

// convert this to projectServiceUrl
export const spacesDataUrl = `${process.env.REACT_APP_CUBE_BACKEND}/spaces/spacesData`;
export const excludedProjectsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/getExcludedProjectsController`;
export const includedProjectsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/getIncludedProjectsController`;
export const issuesCustomFieldsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/issues/getCustomFields`;
export const portalProjectsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/portal/getProjectsList`;
export const projectServiceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/projectsController`;
export const companyAllUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/companies/allUsersController`;
export const companyUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/companies/usersController`;
export const inviteUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/accounts/inviteUsers`;
export const excludedUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/accounts/excludedUsersController`;
export const includedUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/accounts/includedUsersController`;
export const companyInvitedUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/companies/invitedUsersController`;
export const portalCompaniesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/companies/companiesController`;
export const portalRegionsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/regions/regionsController`;
export const positionDataUrl = `${process.env.REACT_APP_CUBE_BACKEND}/positions/positionsController`;
export const portalUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalUsers`;
export const departmentUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/departments/usersController`;
export const companyDepartmentsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/departments/departmentsController`;
export const portalProfilesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalProfiles`;
export const estimateListUrl = `${process.env.REACT_APP_CUBE_BACKEND}/estimate/getEstimateList`;
export const tasksListUrl = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/tasksController`;
export const estimateFilesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/estimate/getEstimateFiles`;
export const estimateItemsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/estimate/getEstimateItems`;
export const getChildEstimateItemsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/estimate/getChildEstimateItems`;
export const estimateTakeoffsListUrl = `${process.env.REACT_APP_CUBE_BACKEND}/takeoffs/getTakeoffsList`;
export const estimateMeasurementsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/measurements/getMeasurements`;
export const unitTypesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/units/getUnitTypes`;
export const portalMeasurementUnitsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/units/getMeasurementUnits`;
export const portalPurchaseUnitsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/units/getPurchaseUnits`;
export const getMeasurementUnitSymbolsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/units/getMeasurementUnitSymbols`;
export const getTransmittalDataUrl = `${process.env.REACT_APP_CUBE_BACKEND}/transmittals/getTransmittalData`;
export const queriesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/queries/queriesData`;
export const assetRegisterUrl = `${process.env.REACT_APP_CUBE_BACKEND}/asset/assetRegisterController`;
export const assetListUrl = `${process.env.REACT_APP_CUBE_BACKEND}/asset/assetListController`;
export const changeOrderUrl = `${process.env.REACT_APP_CUBE_BACKEND}/changeOrder/changeOrderController`;
export const costDatabaseUrl = `${process.env.REACT_APP_CUBE_BACKEND}/dsr/portalGraph`;
export const ganttListUrl = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/getScheduleData`;
export const resourceTypesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/resources/getResourceTypes`;
export const resourceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/resources/getResources`;
export const taskTypeUrl = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/getTaskTypes`;
export const scheduleFilesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/getScheduleFiles`;
export const unassignedTasksUrl = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/getScheduleUnassignedTasks`;
export const getDistinctFilePropertyValuesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/queries/getDistinctFilePropertyValues`;
export const getFilePropertiesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/queries/queryFileProperties`;
export const searchServiceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/search/searchFilesAtPortal`;
export const getCurrencyDataUrl = `${process.env.REACT_APP_CUBE_BACKEND}/currency/getCurrencyData`;
export const getClashTestUrl = `${process.env.REACT_APP_CUBE_BACKEND}/clashTests/getClashDetectionsList`;
export const getProjectRulesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projectRulesController/getProjectRules`;
export const getDsrDataUrl = `${process.env.REACT_APP_CUBE_BACKEND}/dsr/getDsrData`;
export const orgStructureServiceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/orgStructure/orgStructureController`;
export const orgTypeServiceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/orgStructure/getOrgTypes`;
export const orgStructureHeldByUrl = `${process.env.REACT_APP_CUBE_BACKEND}/orgStructure/getOrganitionUsers`;
export const orgStructureReportsToUrl = `${process.env.REACT_APP_CUBE_BACKEND}/orgStructure/getOrganitionReportsToData`;
export const getAppearanceDataUrl = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/getAppearanceData`;
export const getSelectionsDataUrl = `${process.env.REACT_APP_CUBE_BACKEND}/selectionSet/getSelections`;
export const modelTreeUrl = `${process.env.REACT_APP_CUBE_BACKEND}/queries/getModelTree`;
export const getSpacesList = `${process.env.REACT_APP_CUBE_BACKEND}/spaces/getSpacesList`;
export const getSpaceUsersList = `${process.env.REACT_APP_CUBE_BACKEND}/spaces/getUsersInSpaces`;
export const getIssuesList = `${process.env.REACT_APP_CUBE_BACKEND}/issues/getIssuesDataByPortal`;
// export const getFilesList = `${process.env.REACT_APP_CUBE_BACKEND}/projects/projectLevelFiles`;
export const getFilesList = `${process.env.REACT_APP_CUBE_BACKEND}/projects/getFilesList`;
export const getValidItems = `${process.env.REACT_APP_CUBE_BACKEND}/dsr/getValidItemsToImportInBoq`;
export const getDsrItemsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/dsr/getAllDsrItems`;
export const getValidItemsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/estimate/getValidItems`;
export const getAllVersionsOfDsrUrl = `${process.env.REACT_APP_CUBE_BACKEND}/dsr/getAllVersionsOfDsr`;
export const getOnlyNodesOfModelUrl = `${process.env.REACT_APP_CUBE_BACKEND}/queries/getOnlyNodesOfModel`;
export const performGanttOperationsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/performGanttOperations`;
export const performGanttOperationsUrlNew = `${process.env.REACT_APP_CUBE_BACKEND}/tasks/performGanttOperationsNew`;
export const workflowsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/workflows/workflowsService`;
export const workflowReviewLevelsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/workflows/workflowReviewLevelsService`;
export const reviewsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/reviews/reviewsService`;
export const reviewsLevelUrl = `${process.env.REACT_APP_CUBE_BACKEND}/reviews/reviewsLevelService`;
export const getResourcesInReviewUrl = `${process.env.REACT_APP_CUBE_BACKEND}/reviews/getResourcesInReviewService`;
export const getTimePeriodsTypesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/workflows/getTimePeriodsTypes`;
export const getContractListsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/list`;
export const userTypesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/workflows/getUserTypes`;
export const spaceUsersListUrl = `${process.env.REACT_APP_CUBE_BACKEND}/workflows/getSpaceUsersList`;
export const getClashDetectionVersionResultOperationsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/clashTests/getClashDetectionVersionResult`;
export const getProjectTeamsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/teamsController`;
export const locationUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/locationController`;
export const FormTypeUrl = `${process.env.REACT_APP_CUBE_BACKEND}/forms/formTypesController`;
export const FormUrl = `${process.env.REACT_APP_CUBE_BACKEND}/forms/formController`;
export const GroupUrl = `${process.env.REACT_APP_CUBE_BACKEND}/groups/updateGroupsData`;
export const SpaceGroupsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/groups/spaceGroupsController`;
export const taskInspectionAndProgressFormUrl = `${process.env.REACT_APP_CUBE_BACKEND}/forms/taskInspectionAndProgressFormsController`;
export const additionalCostsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/estimate/getAdditionalCost`;
export const statusListInWorkflowUrl = `${process.env.REACT_APP_CUBE_BACKEND}/workflows/getStatusListInWorkflow`;
export const transitionsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/workflows/transitionsService`;
export const getPortalLevelStatusListUrl = `${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalLevelStatusList`;
export const listOfStatusAvailableUrl = `${process.env.REACT_APP_CUBE_BACKEND}/reviews/fetchListOfStatusesAvailable`;
export const defaultListOfStatusAvailableUrl = `${process.env.REACT_APP_CUBE_BACKEND}/reviews/fetchDefaultListOfStatusesAvailable`;
export const activityLogsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/activity/activityLogs`;
export const formFlowServiceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/forms/formFlowController`;
export const constituentFilesOfMergedFileUrl = `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getConstituentFilesOfMergedFile`;
export const getConstituentFilesMatrix = `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveConstituentMatrix`;
export const projectUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/getProjectUsersList`;
export const mailsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/mailbox/getMails`;
export const formUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getUsersList`;
export const getProjectVisibilityUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/getProjectVisibilityUsers`;
export const getProjectWhitelistEmailsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/getProjectWhitelistEmails`;
export const reviewsForResourceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/reviews/getReviewsListForEntity`;
export const contractsControllerUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/contractsController`;
export const contractItemsUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/getContractLinkedBoqItems`;
export const contractEntitiesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/getContractLinkedEntities`;
export const takeoffAutomationUrl = `${process.env.REACT_APP_CUBE_BACKEND}/takeoffs/getAutomationQueries`;
export const mileStoneServiceUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/mileStoneController`;
export const resourceActivityInReviewUrl = `${process.env.REACT_APP_CUBE_BACKEND}/reviews/getResourceActivityInReview`;
export const chargesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/estimate/chargesList`;
export const getFormMileStonesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/getFormMileStones`;
export const WBSServicesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/WBSController`;
export const contractWBSServicesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/contractWBSController`;
export const getProjectRolesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projects/getProjectRoles`;
export const getEscrowFilesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getEscrowFiles`;
export const getEscrowFileBatchesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getEscrowFileBatches`;
export const getStatusList = `${process.env.REACT_APP_CUBE_BACKEND}/statusList/getStatusList`;
export const estimateItemsForContractUrl = `${process.env.REACT_APP_CUBE_BACKEND}/contracts/estimateItemsForContract`;
export const inactiveUsersUrl = `${process.env.REACT_APP_CUBE_BACKEND}/accounts/inactiveUsers`;
export const projectTemplatesUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projectTemplates/projectTemplateData`;
export const spacesInProjectTemplateUrl = `${process.env.REACT_APP_CUBE_BACKEND}/projectTemplates/spacesDataInProjectTemplate`;

export const getStatusListService = getDataManagerObj(getStatusList);
export const estimateItemsForContractService = getDataManagerObj(
  estimateItemsForContractUrl,
);
export const contractWBSService = getDataManagerObj(contractWBSServicesUrl);
export const WBSService = getDataManagerObj(WBSServicesUrl);
export const getFormMileStonesService = getDataManagerObj(getFormMileStonesUrl);
export const mileStoneService = getDataManagerObj(mileStoneServiceUrl);
export const contractEntitiesService = getDataManagerObj(contractEntitiesUrl);
export const contractItemsService = getDataManagerObj(contractItemsUrl);
export const contractsService = getDataManagerObj(contractsControllerUrl);
export const formUsersService = getDataManagerObj(formUsersUrl);
export const projectUsersService = getDataManagerObj(projectUsersUrl);
export const formFlowService = getDataManagerObj(formFlowServiceUrl);
export const taskInspectionAndProgressFormService = getDataManagerObj(
  taskInspectionAndProgressFormUrl,
);
export const FormService = getDataManagerObj(FormUrl);
export const GroupService = getDataManagerObj(GroupUrl);
export const SpaceGroups = getDataManagerObj(SpaceGroupsUrl);
export const FormTypeService = getDataManagerObj(FormTypeUrl);
export const ConstituentFilesMatrix = getDataManagerObj(
  getConstituentFilesMatrix,
);
export const locationService = getDataManagerObj(locationUrl);
export const teamsService = getDataManagerObj(getProjectTeamsUrl);
export const performGanttOperationsService = getDataManagerObj(
  performGanttOperationsUrl,
);
export const selectionService = getDataManagerObj(getSelectionsDataUrl);
export const appearanceService = getDataManagerObj(getAppearanceDataUrl);
export const companyAllUsersService = getDataManagerObj(companyAllUsersUrl);
export const spacesDataService = getDataManagerObj(spacesDataUrl);
export const companyUsersService = getDataManagerObj(companyUsersUrl);
export const companyInvitedUsersService = getDataManagerObj(
  companyInvitedUsersUrl,
);
export const issuesCustomFieldsService = getDataManagerObj(
  issuesCustomFieldsUrl,
);
export const excludedUsersService = getDataManagerObj(excludedUsersUrl);
export const includedUsersService = getDataManagerObj(includedUsersUrl);
export const portalCompaniesService = getDataManagerObj(portalCompaniesUrl);
export const portalRegionsService = getDataManagerObj(portalRegionsUrl);
export const projectsService = getDataManagerObj(projectServiceUrl);
export const positionDataService = getDataManagerObj(positionDataUrl);
export const portalUsersService = getDataManagerObj(portalUsersUrl);
export const portalProjectsService = getDataManagerObj(portalProjectsUrl);
export const departmentUsersService = getDataManagerObj(departmentUsersUrl);
export const companyDepartmentsService = getDataManagerObj(
  companyDepartmentsUrl,
);
export const portalProfilesService = getDataManagerObj(portalProfilesUrl);
export const excludedProjectsService = getDataManagerObj(excludedProjectsUrl);
export const includedProjectsService = getDataManagerObj(includedProjectsUrl);
export const estimateFilesService = getDataManagerObj(estimateFilesUrl);
export const estimateItemsService = getDataManagerObj(estimateItemsUrl);
export const estimateTakeoffsListService = getDataManagerObj(
  estimateTakeoffsListUrl,
);
export const estimateMeasurementsService = getDataManagerObj(
  estimateMeasurementsUrl,
);
export const unitTypesService = getDataManagerObj(unitTypesUrl);
export const portalMeasurementUnitsService = getDataManagerObj(
  portalMeasurementUnitsUrl,
);
export const portalPurchaseUnitsService = getDataManagerObj(
  portalPurchaseUnitsUrl,
);
export const estimateListService = getDataManagerObj(estimateListUrl);
export const getMeasurementUnitSymbolsService = getDataManagerObj(
  getMeasurementUnitSymbolsUrl,
);
export const getTransmittalService = getDataManagerObj(getTransmittalDataUrl);
export const queriesService = getDataManagerObj(queriesUrl);
export const assetRegisterService = getDataManagerObj(assetRegisterUrl);
export const assetListService = getDataManagerObj(assetListUrl);
export const changeOrderService = getDataManagerObj(changeOrderUrl);
export const tasksService = getDataManagerObj(tasksListUrl);
export const costDatabaseService = getDataManagerObj(costDatabaseUrl);
export const ganttService = getDataManagerObj(ganttListUrl);
export const resourceTypeService = getDataManagerObj(resourceTypesUrl);
export const resourceService = getDataManagerObj(resourceUrl);
export const taskTypeService = getDataManagerObj(taskTypeUrl);
export const scheduleFilesService = getDataManagerObj(scheduleFilesUrl);
export const unassignedTasksService = getDataManagerObj(unassignedTasksUrl);
export const getDistinctFilePropertyValuesService = getDataManagerObj(
  getDistinctFilePropertyValuesUrl,
);
export const getFilePropertiesService = getDataManagerObj(getFilePropertiesUrl);
export const getCurrencyData = getDataManagerObj(getCurrencyDataUrl);
export const searchService = getDataManagerObj(searchServiceUrl);
export const clashTestService = getDataManagerObj(getClashTestUrl);
export const projectRulesService = getDataManagerObj(getProjectRulesUrl);
export const getDsrDataService = getDataManagerObj(getDsrDataUrl);
export const orgStructureService = getDataManagerObj(orgStructureServiceUrl);
export const orgUnitTypeService = getDataManagerObj(orgTypeServiceUrl);
export const orgStructureHeldByService = getDataManagerObj(
  orgStructureHeldByUrl,
);
export const orgStructureReportsToService = getDataManagerObj(
  orgStructureReportsToUrl,
);
export const modelTreeService = getDataManagerObj(modelTreeUrl);
export const spaceService = getDataManagerObj(getSpacesList);
export const spaceUsersListService = getDataManagerObj(getSpaceUsersList);
export const issuesService = getDataManagerObj(getIssuesList);
export const filesService = getDataManagerObj(getFilesList);
export const validItemsService = getDataManagerObj(getValidItems);
export const dsrItemsService = getDataManagerObj(getDsrItemsUrl);
export const childEstimateItemsService = getDataManagerObj(
  getChildEstimateItemsUrl,
);
export const getValidItemsService = getDataManagerObj(getValidItemsUrl);
export const getAllVersionsOfDsrService = getDataManagerObj(
  getAllVersionsOfDsrUrl,
);
export const workflowsService = getDataManagerObj(workflowsUrl);
export const workflowReviewLevelsService = getDataManagerObj(
  workflowReviewLevelsUrl,
);
export const reviewsService = getDataManagerObj(reviewsUrl);
export const reviewsLevelService = getDataManagerObj(reviewsLevelUrl);
export const getResourcesInReviewService = getDataManagerObj(
  getResourcesInReviewUrl,
);
export const getTimePeriodsTypesService = getDataManagerObj(
  getTimePeriodsTypesUrl,
);
export const userTypesService = getDataManagerObj(userTypesUrl);
export const spaceUsersService = getDataManagerObj(spaceUsersListUrl);
export const getClashDetectionVersionResultService = getDataManagerObj(
  getClashDetectionVersionResultOperationsUrl,
);
export const additionalCostsService = getDataManagerObj(additionalCostsUrl);
export const statusListInWorkflowService = getDataManagerObj(
  statusListInWorkflowUrl,
);
export const transitionsService = getDataManagerObj(transitionsUrl);
export const getPortalLevelStatusListService = getDataManagerObj(
  getPortalLevelStatusListUrl,
);
export const listOfStatusAvailableService = getDataManagerObj(
  listOfStatusAvailableUrl,
);
export const defaultListOfStatusAvailableService = getDataManagerObj(
  defaultListOfStatusAvailableUrl,
);
export const activityLogsService = getDataManagerObj(activityLogsUrl);
export const constituentFilesOfMergedFileService = getDataManagerObj(
  constituentFilesOfMergedFileUrl,
);
export const mailsService = getDataManagerObj(mailsUrl);
export const takeoffAutomationService = getDataManagerObj(takeoffAutomationUrl);
export const getProjectVisibilityUsersService = getDataManagerObj(
  getProjectVisibilityUsersUrl,
);
export const getProjectWhitelistEmailsService = getDataManagerObj(
  getProjectWhitelistEmailsUrl,
);
// export const contractsItemService = getDataManagerObj(contractsItemurl);
export const reviewsForResourceService = getDataManagerObj(
  reviewsForResourceUrl,
);
export const resourceActivityInReviewService = getDataManagerObj(
  resourceActivityInReviewUrl,
);
export const chargesService = getDataManagerObj(chargesUrl);
export const getProjectRoles = getDataManagerObj(getProjectRolesUrl);
export const inactiveUsersService = getDataManagerObj(inactiveUsersUrl);
export const getEscrowFiles = getDataManagerObj(getEscrowFilesUrl);
export const getEscrowFileBatches = getDataManagerObj(getEscrowFileBatchesUrl);
export const projectTemplatesService = getDataManagerObj(projectTemplatesUrl);
export const spacesServiceInProjectTemplate = getDataManagerObj(
  spacesInProjectTemplateUrl,
);
