import { getUserDetailsById } from "./userDetails";

export const fetchUserTagData = (id) => async (dispatch, getState) => {
  const { userTagData } = getState();

  if (userTagData[id]?.isLoading || userTagData[id]?.isLoaded) {
    return;
  }

  dispatch({ type: "SET_USERTAG_LOADING", id });

  try {
    const response = await getUserDetailsById({ userId: id });
    dispatch({ type: "SET_USERTAG_DATA", id, data: response.data.data });
  } catch (error) {
    console.error("Error fetching user tag data:", error);
  }
};

export const updateUserProfileDetails = (data) => (dispatch) => {
  if (data) {
    dispatch({ type: "SET_UPDATED_USER_PROFILE_DETAILS", data });
  }
};
